import React from 'react';
import { SEO } from '../components/SEO';
import { StaticImage } from 'gatsby-plugin-image';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Layout } from '../layouts/Layout';

export default function IndexPage(props) {
  return (
    <Layout className="relative bg-slate">
      <SEO title={null} titleTemplate="" />
      <Header noHeight className="sticky top-0 left-0 right-0 z-10" />
      <StaticImage src="../resources/page/mission.jpg" alt="mission" className="mt-12" />
      <Footer />
    </Layout>
  );
}
